<template>
  <!-- 新建每日申识 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 5 }"
    >
      <a-form-model-item label="申识标题" prop="title">
        <a-input v-model="form.title" :maxLength="32">
          <div slot="addonAfter">{{ form.title.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="申识内容" :wrapper-col="{ span: 18 }" required>
        <!-- 视频内容 -->
        <QiniuVideoAudio
          :oldFileName="form.oldFileName"
          :type="2"
          @videoFn="videoFn"
          @changeOldFileName="changeOldFileName"
        />
        <div style="font-weight: 600">
          支持mp4格式；建议视频文件不超过500M。
        </div>
      </a-form-model-item>
      <a-form-model-item label="选择商品" :wrapper-col="{ span: 10 }">
        <div class="product-box" v-if="isShowGoods">
          <a-icon
            type="close-circle"
            :style="{ fontSize: '20px', color: '#9FA5AF' }"
            class="product-close"
            @click="closeHandle"
          />
          <div
            class="product-wrap flexbox justify middle"
            :class="{ bg: this.$route.params.id != 0 }"
          >
            <div class="left-wrap flex1 flexbox left">
              <div class="product-img">
                <img class="p-img" :src="goodsDetail.coverUrl" alt="图片" />
              </div>
              <div class="product-info flex1">
                <div style="height: 56px">
                  <div class="p-title more-t">
                    {{ goodsDetail.businessName }}
                  </div>
                  <div
                    v-show="
                      form.businessType == 1 && goodsDetail.pricingType == 0
                    "
                    class="p-price"
                  >
                    免费
                  </div>
                  <div
                    v-show="
                      form.businessType == 1 && goodsDetail.pricingType == 1
                    "
                    class="p-price"
                  >
                    ￥{{ goodsDetail.price }}
                  </div>
                  <div
                    v-show="
                      form.businessType == 1 && goodsDetail.pricingType == 2
                    "
                    class="p-price"
                  >
                    不支持单独售卖
                  </div>
                  <div v-show="form.businessType == 2" class="p-price">
                    ￥{{ goodsDetail.price }}
                  </div>
                </div>
                <div class="p-type">
                  商品类型:{{ form.businessType == "1" ? "课程" : "会员" }}
                </div>
              </div>
            </div>
            <div class="right-wrap">
              <a-button type="primary" @click="() => (modalVisible = true)">
                更换商品
              </a-button>
            </div>
          </div>
        </div>
        <a-button
          v-else
          type="primary"
          size="large"
          @click="() => (modalVisible = true)"
        >
          添加商品
        </a-button>
      </a-form-model-item>
      <a-form-model-item
        ref="putawayFlag	"
        label="上架设置"
        prop="putawayFlag"
        required
      >
        <a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
          <a-radio :style="radioStyle" :value="2">立即上架 </a-radio>
          <a-radio :style="radioStyle11" :value="1"> 定时上架 </a-radio>
          <template v-if="form.putawayFlag == 1">
            <template>
              <a-date-picker
                :locale="locale"
                :value="form.putawayTime ? form.putawayTime : null"
                :format="dateFormat"
                show-time
                placeholder="请选择上架时间"
                @change="putawayTimeChange"
                @ok="putawayTimeOk"
              />
            </template>
          </template>
          <a-radio :style="radioStyle" :value="3"> 暂不上架 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)"
        >返回</a-button
      >
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </FooterToolBar>
    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :type="0"
      :tabType="form.businessType"
      :selectedRowKeys="selectedRowKeys"
      :isShowMember="true"
      @cancel="() => (modalVisible = false)"
      @ok="ok"
    />
  </div>
</template>

<script>
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";
import RadioModal from "@/components/courseMember/RadioModal";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { format } from "@/utils/tools";
import {
  RecommendAddApi,
  RecommendUpdApi,
  RecommendDetailsApi,
} from "@/request/api/indexManage";
export default {
  mixins: [baseMixin],
  components: { QiniuVideoAudio, RadioModal, FooterToolBar },
  created() {
    let id = this.$route.params.id;
    if (id != 0) {
      RecommendDetailsApi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
          this.form.id = this.$route.params.id;
          this.goodsDetail.coverUrl = data.coverUrl;
          this.goodsDetail.businessName = data.businessName;
          this.goodsDetail.price = data.price;
          if (data.businessName != "") {
            this.isShowGoods = true;
          }
        }
      });
    }
  },
  data() {
    return {
      modalVisible: false,
      selectedRowKeys: [],
      goodsDetail: { coverUrl: "", businessName: "", price: "" }, // 商品详情
      isShowGoods: false, // 商品详情是否显示
      locale,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      keepLoading: false, // 保存的按钮是否加载
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      radioStyle11: {
        height: "30px",
        lineHeight: "30px",
      },
      form: {
        id: "",
        title: "",
        businessType: 1, //业务类型[1: 课程 2: 会员],
        businessNo: "",
        oldFileName: "",
        fileUrl: "",
        fileCosKey: "",
        putawayFlag: 2,
        putawayTime: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
        ],
      },
      keepLoading: false,
    };
  },
  methods: {
    moment,
    // 视频函数
    videoFn(obj) {
      setTimeout(() => {
        this.keepLoading = false;
      }, 2000);
      this.form.oldFileName = obj.oldFileName;
      this.form.fileCosKey = obj.fileCosKey;
      this.form.fileUrl = obj.fileUrl;
      this.form.videoTime = obj.videoTime;
      this.form.fileSize = obj.fileSize;
    },
    // 改变视频文件名
    changeOldFileName(oldFileName) {
      this.form.oldFileName = oldFileName;
    },
    // 商品关闭按钮
    closeHandle() {
      this.goodsDetail = null;
      this.isShowGoods = false;
    },
    // 模态框的确定按钮
    ok(SelectedRowKeys, obj, businessType) {
      if (obj.memberNo || obj.key) {
        if (obj.memberNo) {
          this.form.businessNo = obj.memberNo;
          this.$set(this.goodsDetail, "businessName", obj.memberName);
          this.$set(this.goodsDetail, "coverUrl", obj.coverImg);
          this.$set(this.goodsDetail, "price", obj.price);
        } else {
          this.form.businessNo = obj.key;
          this.goodsDetail = obj;
          this.$set(this.goodsDetail, "businessName", obj.name);
          this.$set(this.goodsDetail, "coverUrl", obj.coverUrl);
          this.$set(this.goodsDetail, "price", obj.price);
        }
        this.form.businessType = Number(businessType);
        this.modalVisible = false;
        this.isShowGoods = true;
      } else {
        this.modalVisible = false;
      }
    },
    // 上架设置改变的函数
    putawayFlagChange(e) {
      this.form.putawayFlag = e.target.value;
    },
    // 上架时间发生发生改变的回调
    putawayTimeChange(value, dateString) {
      this.form.putawayTime = value;
    },
    // 上架时间OK的回调
    putawayTimeOk(value) {
      this.form.putawayTime = value;
    },
    // 保存的按钮
    keepClick() {
      this.form.putawayTime = format(
        new Date(this.form.putawayTime),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      if (
        this.form.putawayTime == "1970-01-01 08:00:00" ||
        this.form.putawayTime == "NaN-aN-aN aN:aN:aN"
      ) {
        this.form.putawayTime = "";
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.putawayFlag != 0) {
            if (this.form.putawayFlag == 1 && this.form.putawayTime == "") {
              message.error("请选择上架时间");
              return;
            }
            if (this.$route.params.id != 0) {
              RecommendUpdApi(this.form).then(({ code, data }) => {
                if (code == 200) {
                  message.success("修改成功");
                  location.href = "/indexManage/indexConfig";
                }
              });
            } else {
              RecommendAddApi(this.form).then(({ code, data }) => {
                if (code == 200) {
                  message.success("新增成功");
                  location.href = "/indexManage/indexConfig";
                }
              });
            }
          } else {
            message.error("请选择上架设置");
          }
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.product-box {
  position: relative;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: red;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.ant-form-item-control {
  line-height: 22px;
}
</style>